@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700&display=swap');

@import "bootstrap/scss/functions";

@import "variables";
@import "bootstrap/scss/variables";

@import "bootstrap/scss/maps";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/root";

@import "bootstrap/scss/utilities";
@import "bootstrap/scss/reboot";
@import "bootstrap/scss/type";
@import "bootstrap/scss/images";
@import "bootstrap/scss/containers";
@import "bootstrap/scss/grid";
@import "bootstrap/scss/helpers";

@import "bootstrap/scss/utilities/api";

@import "bootstrap/scss/transitions";
@import "~bootstrap/scss/accordion";
@import "bootstrap/scss/nav";
@import "bootstrap/scss/list-group";
@import "bootstrap/scss/close";
@import "bootstrap/scss/placeholders";
@import "bootstrap/scss/dropdown";

.bg-auth {
  background      : url('../images/bg-auth.png') no-repeat;
  background-size : cover;
}
.content-area{
  min-height:calc(100vh - 20rem)
}

.light-blue{
  background-color: #0d6efd !important;
}

.fs14{
  font-size: 14px;
}

.h-15{
  height: 15px !important;
}

#upload-photo {
  opacity: 0;
  position: absolute;
  z-index: -1;
}
#upload-office-photo {
  opacity: 0;
  position: absolute;
  z-index: -1;
}

.minWidth-Auto {
  min-width: auto !important;
}

.pointer {
  cursor: pointer !important;
}
@-webkit-keyframes fadeInDown {
  0% {
     opacity: 0;
     -webkit-transform: translateY(-20px);
  }
  100% {
     opacity: 1;
     -webkit-transform: translateY(0);
  }
}

@keyframes fadeInDown {
  0% {
     opacity: 0;
     transform: translateY(-20px);
  }
  100% {
     opacity: 1;
     transform: translateY(0);
  } 
}
.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
}
// .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input, .css-1kob5o4-MuiInputBase-root-MuiOutlinedInput-root{
//   padding: 5px 14px !important;
// }
// .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input
// {
//   padding: 5px 14px !important;
// }
.mb-3{
  margin-bottom: unset !important; 
}
.mb-4{
  margin-bottom: unset !important; 
}
.form-label, .from-label{
  margin-bottom: unset !important; 
  font-size: 14px;
}

.bg-light-1{
  background-color: #e1e5e9 !important;
}
.css-1o6z5ng{
  background-color: white !important;
}
.css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input{
  background-color: white !important;
}
.css-1fkukmv-MuiTableCell-root{
  font-weight: bold !important;
  font-size: 1rem !important;
  color: #1b385f !important;
}
.css-jefd33-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root{
  width: 100% !important;
  height: 2.4375em !important;
}
.css-gwpmft-MuiTableCell-root{
  font-weight: bold !important;
  font-size: 1rem !important;
  color: #1b385f !important;
}
.css-3hknbo-MuiTableCell-root.MuiTableCell-head{
  font-weight: bold !important;
  font-size: 1rem !important;
  color: #1b385f !important;
}

.rounded--1{
  border-radius: 1rem !important;
}

.sampler{
 margin-top:4rem !important;
}
.circleIcon{
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.visiblityIcon{
  top: 50%;
  left: 55%;
  transform: translate(-50%, -50%);
}
.circle{
  height: 10rem;
  border-radius: 10px !important;
}
.totalView{
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  color:white;
  margin-top: 1.1rem;
}
.nav-link:hover{
  background-color: #EE3138 !important;
}
// .nav-link {
//   &.active {
//     background-color:#EE3138 !important;
//   }}

.active{
  background-color:#EE3138 !important; 
}

.searchBox{
  border-radius: 10px !important;
  background-color: #1b385f !important;
}
.css-1nm8xsi-MuiFormHelperText-root{
  color:#ef3d18 !important ;
}
// .css-1d3z3hw-MuiOutlinedInput-notchedOutline{
//   border-color: white !important;
// }

// .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input{
//   background-color: #1b385f !important;
// }

.css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select{
  background-color: white;
}

.roundedTop{
  border-top-left-radius: 1rem !important;
  border-top-right-radius: 1rem !important;
}

.capitalize{
  text-transform: capitalize;
}

.mobileView{
  display: none !important;
}
.mobileBlock{
  display: block !important;
}

@media only screen and (max-width: 600px) {
  .mobileViewRowCenter{
    justify-content: center !important;
    margin: 0;
  }

  .mobileView{
    display: block !important;
  }
  .mobileBlock{
    display: none !important;
  }

  .flexWrap{
    flex-wrap: wrap;
  }
}